<template>
    <div class="main-container">
      <h5 class="pageTitle">비행 목적 수정/삭제</h5>
      <div class="form-container">
        <div class="form-group">
          <label for="purpose">비행목적</label>
          <input v-model="form.purpose" type="text" class="input-box" />
        </div>
  
        <div class="button-container">
          <button class="button-list" @click="goToList">목록</button>
          <button class="button-update" @click="updatePurpose">수정</button>
          <button class="button-delete" @click="deletePurpose">삭제</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from "@/axios";
  import Swal from "sweetalert2";
  
  export default {
    name: "FlightPurposeUpdateDelete",
    data() {
      return {
        form: {
          uuid: this.$route.params.id || "",
          purpose: this.$route.query.purpose || "", // 쿼리로 받은 경우
        },
      };
    },
    mounted() {
      // 쿼리 없이 직접 접근하면 조회 API 호출
      if (!this.form.purpose) {
        this.fetchPurpose();
      }
    },
    methods: {
      async fetchPurpose() {
        try {
          const token = this.getToken();
          const response = await axios.get(`/master/flight-purpose/${this.form.uuid}`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          this.form.purpose = response.data.purpose;
        } catch (error) {
          console.error("비행목적 단건 조회 실패:", error);
        }
      },
  
      async updatePurpose() {
        try {
          const token = this.getToken();
          await axios.put(
            `/master/flight-purpose/${this.form.uuid}`,
            { purpose: this.form.purpose },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );
          
          Swal.fire("성공", "비행목적이 수정되었습니다.", "success");
          this.goToList();
        } catch (error) {
          Swal.fire("오류", "수정 중 문제가 발생했습니다.", "error");
          console.error("비행목적 수정 실패:", error);
        }
      },
  
      async deletePurpose() {
        try {
          const token = this.getToken();
          await axios.delete(`/master/flight-purpose/${this.form.uuid}`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          Swal.fire("성공", "비행목적이 삭제되었습니다.", "success");
          this.goToList();
        } catch (error) {
          Swal.fire("오류", "삭제 중 문제가 발생했습니다.", "error");
          console.error("비행목적 삭제 실패:", error);
        }
      },
  
      getToken() {
        const tokenData = JSON.parse(sessionStorage.getItem("token"));
        return tokenData ? tokenData.access_token : "";
      },
  
      goToList() {
        this.$router.push("/master-flightPurposeRead");
      },
    },
  };
  </script>
<style scoped>
/* 동일한 스타일 유지 */
.main-container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pageTitle {
  font-weight: bold;
  margin-bottom: 40px;
  text-align: left;
  width: 100%;
}

.form-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 70%;
  max-width: 800px;
}

.form-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.input-box,
.select-box {
  flex: 1;
  padding: 8px;
  margin-right: 40px;
  border: 1px solid rgba(130, 128, 128, 0.26);
  border-radius: 3px;
  max-width: 300px;
}

.input-select {
  width: 200px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.button-container {
  display: flex;
  gap: 20px;
  justify-content: flex-start;
}

.button-list {
  background-color: #ff9800;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.button-update {
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.button-delete {
  background-color: #f44336;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}
</style>
  